import React from 'react';

const LogoIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 541 541" fill="#ff0000">
<path d="M519.077 201.138C528.061 203.135 533.219 211.785 535.049 216.111C536.047 199.641 521.012 181.174 511.092 178.179C458.186 162.208 394.299 141.246 363.354 31.4445C354.869 11.9797 333.907 5.99048 323.924 5.99048C326.42 8.98522 332.409 5.99048 339.896 21.4625C353.372 148.234 471.162 205.131 519.077 201.138Z" fill="url(#paint0_linear)"/>
<path d="M20.0124 338.39C11.0284 336.394 5.87077 327.743 4.0407 323.417C3.04257 339.887 18.0774 358.354 27.9981 361.349C80.9041 377.32 144.79 398.282 175.735 508.084C184.22 527.549 205.183 533.538 215.165 533.538C212.67 530.543 206.68 533.538 199.194 518.066C185.718 391.295 67.9272 334.397 20.0124 338.39Z" fill="url(#paint1_linear)"/>
<path d="M412.267 39.9292C419.255 77.8607 470.164 124.277 500.111 127.771C492.624 107.308 445.708 58.895 412.267 39.9292Z" fill="url(#paint2_linear)"/>
<path d="M128.771 501.097C121.784 463.165 70.8745 416.749 40.9277 413.255C48.4144 433.718 95.331 482.131 128.771 501.097Z" fill="url(#paint3_linear)"/>
<path d="M135.027 279.595C134.85 276.757 134.761 273.895 134.761 271.012C134.761 195.761 195.765 134.758 271.018 134.758C274.867 134.758 278.677 134.917 282.445 135.23C287.296 144.627 292.762 154 298.879 163.228C289.975 160.934 280.639 159.713 271.018 159.713C209.548 159.713 159.716 209.543 159.716 271.012C159.716 279.624 160.694 288.007 162.546 296.058C153.855 290.132 144.686 284.621 135.027 279.595Z" fill="url(#paint4_radial)"/>
<path d="M140.111 308.944C152.658 352.321 186.183 386.793 228.985 400.659C208.087 365.541 178.836 332.837 140.111 308.944Z" fill="url(#paint5_radial)"/>
<path d="M406.833 259.945C407.126 263.596 407.276 267.286 407.276 271.012C407.276 346.263 346.271 407.266 271.018 407.266C266.795 407.266 262.616 407.074 258.49 406.698C253.616 397.199 248.201 387.781 242.225 378.551C251.409 381.004 261.061 382.311 271.018 382.311C332.489 382.311 382.32 332.481 382.32 271.012C382.32 261.548 381.139 252.36 378.916 243.588C387.696 249.421 396.995 254.896 406.833 259.945Z" fill="url(#paint6_radial)"/>
<path d="M401.131 230.431C361.195 206.284 332.116 174.319 311.935 141.008C354.392 154.357 387.888 187.929 401.131 230.431Z" fill="url(#paint7_radial)"/>
<path d="M472.673 284.652C465.719 385.543 384.91 466.12 283.917 472.711C281.76 464.609 279.229 456.382 276.31 448.099C369.484 445.117 444.544 370.401 448.074 277.369C455.993 280.057 464.19 282.493 472.673 284.652Z" fill="url(#paint8_linear)"/>
<path d="M257.54 68.2876C259.434 76.3129 261.767 84.5576 264.566 92.9314C172.016 95.9783 97.3778 169.8 93.0352 262.014C85.1053 259.435 76.9117 257.156 68.4495 255.205C75.9548 154.722 156.743 74.659 257.54 68.2876Z" fill="url(#paint9_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M297.939 33.2609L298.292 36.0835C307.373 108.726 363.809 219.665 506.118 244.913C529.017 248.975 537.372 268.954 539.45 283.982C541.567 299.294 539.061 317.446 534.22 335.427C524.405 371.881 502.767 415.7 472.785 451.463C444.204 485.554 394.812 513.974 352.591 528.832C331.449 536.272 309.808 541.149 291.735 540.659C282.742 540.416 272.646 538.804 263.749 533.756C253.946 528.193 246.571 518.907 244.41 506.549C229.493 421.245 165.797 313.876 35.2737 295.749C22.5478 293.981 12.9355 286.598 7.22647 276.635C2.10068 267.689 0.500664 257.484 0.245204 248.498C-0.270197 230.368 4.51423 208.545 11.7251 187.394C26.0015 145.517 53.4172 96.0545 85.6555 70.7747C121.98 42.2912 164.899 19.3562 201.569 8.19307C219.69 2.67654 238.127 -0.54019 254.231 1.13947C270.634 2.8503 289.093 10.513 296.908 30.6098L297.939 33.2609ZM275.51 38.9311C261.535 2.99592 168.701 34.8305 99.823 88.841C45.4198 131.501 -2.28824 267.353 38.4321 273.008C182.176 292.972 251.054 411.259 267.026 502.594C274.075 542.908 405.102 496.458 455.191 436.713C510.819 370.36 535.548 273.451 502.107 267.518C347.383 240.068 285.493 118.787 275.51 38.9311Z" fill="url(#paint10_radial)"/>
<defs>
<linearGradient id="paint0_linear" x1="429.51" y1="5.99048" x2="429.51" y2="216.111" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="109.579" y1="533.538" x2="109.579" y2="323.417" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="456.189" y1="39.9292" x2="456.189" y2="127.771" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="84.8496" y1="501.097" x2="84.8496" y2="413.255" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.019 271.012) rotate(90) scale(136.254 136.258)">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.77"/>
</radialGradient>
<radialGradient id="paint5_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.019 271.012) rotate(90) scale(136.254 136.258)">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.77"/>
</radialGradient>
<radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.019 271.012) rotate(90) scale(136.254 136.258)">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.77"/>
</radialGradient>
<radialGradient id="paint7_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.019 271.012) rotate(90) scale(136.254 136.258)">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.77"/>
</radialGradient>
<linearGradient id="paint8_linear" x1="270.722" y1="67.8787" x2="270.722" y2="473.147" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="270.722" y1="67.8787" x2="270.722" y2="473.147" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB8C00"/>
<stop offset="1" stop-color="#FB8C00" stop-opacity="0.51"/>
</linearGradient>
<radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(270.208 270.693) rotate(90) scale(247.042 247.042)">
<stop stop-color="#DD5D00"/>
<stop offset="1" stop-color="#ED883F"/>
</radialGradient>
</defs>
</svg>
);

export default LogoIcon;
